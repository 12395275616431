import React, { useState, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import { auth, db } from "../config/fbinit";
import { onAuthStateChanged } from "@firebase/auth";
import { collection, getDocs, query, where } from "@firebase/firestore";
import Swal from "sweetalert2";
import PoliInmigrantes from "./policiaDeInmigrantes";

const Aduana = ({ formData, setFormData, email }) => {
  const [user, setUser] = useState({});
  const [level, setLevel] = useState(0);
  const [amigo, setAmigito] = useState(0);

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  useLayoutEffect(() => {
    const getLevel = async () => {
      try {
        const usersCollectionReference = collection(db, "RetosPersonalizados");
        const q = query(usersCollectionReference, where("email", "==", email));
        const loggedUserData = await getDocs(q);
        //   console.log("REFERENCIA", usersCollectionReference);
        if (loggedUserData.docs[0].data().email != null) {
          setFormData({
            correo: loggedUserData.docs[0].data().email,
            nombre: loggedUserData.docs[0].data().nombre,
            fechaInicio: loggedUserData.docs[0].data().fechaInicio,
            fechaFinal: loggedUserData.docs[0].data().fechaFinal,
            poster: loggedUserData.docs[0].data().poster,
            pdfEntrenamiento: loggedUserData.docs[0].data().pdfEntrenamiento,
            pdfAlimentacion: loggedUserData.docs[0].data().pdfAlimentacion,
            descLunes: loggedUserData.docs[0].data().descLunes,
            vidLunes: loggedUserData.docs[0].data().vidLunes,
            descMartes: loggedUserData.docs[0].data().descMartes,
            vidMartes: loggedUserData.docs[0].data().vidMartes,
            descMiercoles: loggedUserData.docs[0].data().descMiercoles,
            vidMiercoles: loggedUserData.docs[0].data().vidMiercoles,
            descJueves: loggedUserData.docs[0].data().descJueves,
            vidJueves: loggedUserData.docs[0].data().vidJueves,
            descViernes: loggedUserData.docs[0].data().descViernes,
            vidViernes: loggedUserData.docs[0].data().vidViernes,
            descSabado: loggedUserData.docs[0].data().descSabado,
            vidSabado: loggedUserData.docs[0].data().vidSabado,
          });
        }
      } catch (e) {
        return null;
      }
    };
    getLevel();
  }, [user, level]);
  return null;
};

export default Aduana;
