import React, { Component, useState, useRef, useEffect } from "react";

import SignUp from "./signUp";
import { auth, db } from "../config/fbinit";
import Calenadario from "./Reto30Dias";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "@firebase/auth";

const BridgeCheckUp = () => {
  const [user, setUser] = useState({});
  const [isUseralive, setUserAlive] = useState(false);
  const [check, setCheckUp] = useState(false);

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  useEffect(() => {
    if (user != null) {
      setUserAlive(true);
    }

    return function cleanup() {
      setUserAlive(false);
      setCheckUp(true);
    };
  }, [user]);

  if (isUseralive !== false || user !== null) {
    return (
      <div>
        <Calenadario />
      </div>
    );
  } else {
    return (
      <div>
        <SignUp />
      </div>
    );
  }
};

export default BridgeCheckUp;
