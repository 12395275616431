import React, { useState, useLayoutEffect } from "react";

import { Redirect } from "react-router-dom";
import "../css/pagUsuarios.css";
import { auth, db } from "../config/fbinit";
import { onAuthStateChanged } from "@firebase/auth";

const PagUsuarios = () => {
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState({});

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
    if (currentUser != null) {
      setUserId(currentUser.reloadUserInfo.localId);
    }
  });

  if (user !== null) {
    return (
      <div className="paginaUsuario">
        <div>
          <h1 className="correoCuenta">Hola {user.email}</h1>
        </div>
        {/* <div className="divPagUsuario">
          <h3 className="txtPagUsuario">
            Si te interesa participar en el reto fit de 30 días, has click en el
            botón de abajo
          </h3>
          <button className="btnPagUsuario">Contratar reto de 30 días</button>
        </div> */}
        <div className="divPagUsuario">
          <h3 className="txtPagUsuario">
            Si tienes contratado un reto personalizado, has click en el botón de
            abajo
          </h3>
          <button
            className="btnPagUsuario"
            onClick={(event) => (window.location.href = "/Reto_" + userId)}
          >
            Revisar retos personalizados
          </button>
        </div>
        <button
          className="btnPagUsuario"
          onClick={(event) => (window.location.href = "/")}
        >
          Regresar a la Homepage
        </button>
      </div>
    );
  } else {
    return <Redirect to={"/signUp"} />;
  }
};

export default PagUsuarios;
