import React, { useState } from "react";
import { sendPasswordResetEmail, getAuth } from "@firebase/auth";
import Swal from "sweetalert2";

import "../css/contra.css";

const ResetPassword = () => {
  const [registerEmail, setRegisterEmail] = useState("");

  const onChangeRegisterMail = (e) => {
    setRegisterEmail(e.target.value);
  };

  const resetearContra = async (e) => {
    const authReset = getAuth();
    e.preventDefault();
    try {
      const contrasena = await sendPasswordResetEmail(
        authReset,
        registerEmail
      ).then(
        Swal.fire({
          icon: "success",
          title: "Correo enviado",
          text: "Por favor revisa tu correo para cambiar tú contraseña",
        })
      );
    } catch (err) {
      switch (err.code) {
        case "auth/user-not-found":
          Swal.fire({
            icon: "error",
            title: "Cuenta no encontrada",
            text: "Ese correo no esta registrado con ninguna cuenta",
          });
          break;
      }
    }
  };

  return (
    <div>
      <div className="resetContra">
        <h1 className="tituloContra">Recupera tu cuenta</h1>
        <form onSubmit={resetearContra}>
          <div>
            <h2 className="nombreContra">Ingresa el correo de la cuenta</h2>
            <input
              className="inputContra"
              onChange={onChangeRegisterMail}
              type="email"
            />
          </div>
          <button className="btnContra">Enviar correo</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
