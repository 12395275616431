import { useLayoutEffect } from "react";
import { collection, getDocs } from "@firebase/firestore";
import { db } from "../config/fbinit";

const Aduana = ({ data, setData }) => {
  useLayoutEffect(() => {
    const getLevel = async () => {
      try {
        const usersCollectionReference = collection(db, "AntesyDespues");
        const loggedUserData = await getDocs(usersCollectionReference);
        //   console.log("REFERENCIA", usersCollectionReference);
        const antDes = await loggedUserData.docs[0].data();
        setData({
          imagen1: antDes.imagen1,
          imagen2: antDes.imagen2,
          imagen3: antDes.imagen3,
        });
      } catch (e) {
        return null;
      }
    };
    getLevel();
  }, []);
  return null;
};

export default Aduana;
