import React, { useState } from "react";
import { Redirect } from "react-router-dom";
// import { Document, Page, pdfjs } from "react-pdf";
//importar css para estilar
import "../css/curso.css";

import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

import corriendo from "../multimedia/Corriendo.png";
import pesa from "../multimedia/pesa.png";
import tenis from "../multimedia/Tenis.png";
import medalla from "../multimedia/Medalla.png";
import corazon from "../multimedia/Corazon.png";
import botella from "../multimedia/Botella.png";
import luna from "../multimedia/Luna.png";

import RevisionRetoEsp from "../navbars/RevisarRetoEsp";

import { auth, db } from "../config/fbinit";
import { onAuthStateChanged, signOut } from "@firebase/auth";

const Calendario = () => {
  const [user, setUser] = useState({});
  const [blocking, setBlocking] = useState(false);

  const [retoEsp, setRetoEsp] = useState(true);
  const [nombreRetoEsp, setNombreRetoEsp] = useState(null);
  const [reflexionEsp, setReflexionEsp] = useState(null);

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  // toggleBlocking() {
  //   this.setState({ blocking: !this.state.blocking });
  // }

  const RevisarReto = () => {
    if (retoEsp == true) {
      return null;
    } else {
      return <Redirect to={"/"} />;
    }
  };

  return (
    <div className="main">
      <RevisionRetoEsp
        setRetoEsp={setRetoEsp}
        setNombreRetoEsp={setNombreRetoEsp}
        setReflexionEsp={setReflexionEsp}
      />
      {RevisarReto()}
      <h1 className="tituloReto">Hola! Bienvenido al {nombreRetoEsp}</h1>
      {/* <button onClick={(e) => this.toggleBlocking()}>Toggle bloqueo</button> */}
      <div className="reflexion">
        <h1 className="refl">Reflexión del mes</h1>
        <h2 className="reflexionmes">{reflexionEsp}</h2>
      </div>

      <div className="calendarioRetos">
        <h1 className="semanaCalendario">RETO FIT TABS</h1>
        <BlockUi tag="div" blocking={blocking}>
          <div className="semana">
            <div className="semret">
              <a className=" sem">Semana 1</a>
            </div>
            <a href="/retoLunesEspecializadoSem1y2" className="btn diaSemana">
              <img src={corriendo} className="cor" />
              <h1>Reto del Lunes</h1>
            </a>
            <a href="/retoMartesEspecializadoSem1y2" className="btn diaSemana">
              <img src={pesa} className="cor" />
              <h1>Reto del Martes</h1>
            </a>
            <a
              href="/retoMiercolesEspecializadoSem1y2"
              className="btn diaSemana"
            >
              <img src={tenis} className="cor" />
              <h1>Reto del Miércoles</h1>
            </a>
            <a href="/retoJuevesEspecializadoSem1y2" className="btn diaSemana">
              <img src={botella} className="cor" />
              <h1>Reto del Jueves</h1>
            </a>
            <a href="/retoVIernesEspecializadoSem1y2" className="btn diaSemana">
              <img src={corazon} className="cor" />
              <h1>Reto del Viernes</h1>
            </a>
            <a href="/retoSabadoEspecializadoSem1y2" className="btn diaSemana">
              <img src={medalla} className="cor" />
              <h1>Reto del Sábado</h1>
            </a>
            <a className="btn2 diaSemana btn3">
              <img src={luna} className="cor" />
              <h1>Domingo es libre</h1>
            </a>
          </div>
          <div className="semana">
            <div className="semret">
              <a className=" sem">Semana 2</a>
            </div>
            <a href="/retoLunesEspecializadoSem1y2" className="btn diaSemana">
              <img src={corriendo} className="cor" />
              <h1>Reto del Lunes</h1>
            </a>
            <a href="/retoMartesEspecializadoSem1y2" className="btn diaSemana">
              <img src={pesa} className="cor" />
              <h1>Reto del Martes</h1>
            </a>
            <a
              href="/retoMiercolesEspecializadoSem1y2"
              className="btn diaSemana"
            >
              <img src={tenis} className="cor" />
              <h1>Reto del Miércoles</h1>
            </a>
            <a href="/retoJuevesEspecializadoSem1y2" className="btn diaSemana">
              <img src={botella} className="cor" />
              <h1>Reto del Jueves</h1>
            </a>
            <a href="/retoVIernesEspecializadoSem1y2" className="btn diaSemana">
              <img src={corazon} className="cor" />
              <h1>Reto del Viernes</h1>
            </a>
            <a href="/retoSabadoEspecializadoSem1y2" className="btn diaSemana">
              <img src={medalla} className="cor" />
              <h1>Reto del Sábado</h1>
            </a>
            <a className="btn2 diaSemana btn3">
              <img src={luna} className="cor" />
              <h1>Domingo es libre</h1>
            </a>
          </div>
        </BlockUi>
        <BlockUi tag="div" blocking={blocking}>
          <div className="semana">
            <div className="semret">
              <a className=" sem">Semana 3</a>
            </div>
            <a href="/retoLunesEspecializadoSem3y4" className="btn diaSemana">
              <img src={corriendo} className="cor" />
              <h1>Reto del Lunes</h1>
            </a>
            <a href="/retoMartesEspecializadoSem3y4" className="btn diaSemana">
              <img src={pesa} className="cor" />
              <h1>Reto del Martes</h1>
            </a>
            <a
              href="/retoMiercolesEspecializadoSem3y4"
              className="btn diaSemana"
            >
              <img src={tenis} className="cor" />
              <h1>Reto del Miércoles</h1>
            </a>
            <a href="/retoJuevesEspecializadoSem3y4" className="btn diaSemana">
              <img src={botella} className="cor" />
              <h1>Reto del Jueves</h1>
            </a>
            <a href="/retoVIernesEspecializadoSem3y4" className="btn diaSemana">
              <img src={corazon} className="cor" />
              <h1>Reto del Viernes</h1>
            </a>
            <a href="/retoSabadoEspecializadoSem3y4" className="btn diaSemana">
              <img src={medalla} className="cor" />
              <h1>Reto del Sábado</h1>
            </a>
            <a className="btn2 diaSemana btn3">
              <img src={luna} className="cor" />
              <h1>Domingo es libre</h1>
            </a>
          </div>
          <div className="semana">
            <div className="semret">
              <a className=" sem">Semana 4</a>
            </div>
            <a href="/retoLunesEspecializadoSem3y4" className="btn diaSemana">
              <img src={corriendo} className="cor" />
              <h1>Reto del Lunes</h1>
            </a>
            <a href="/retoMartesEspecializadoSem3y4" className="btn diaSemana">
              <img src={pesa} className="cor" />
              <h1>Reto del Martes</h1>
            </a>
            <a
              href="/retoMiercolesEspecializadoSem3y4"
              className="btn diaSemana"
            >
              <img src={tenis} className="cor" />
              <h1>Reto del Miércoles</h1>
            </a>
            <a href="/retoJuevesEspecializadoSem3y4" className="btn diaSemana">
              <img src={botella} className="cor" />
              <h1>Reto del Jueves</h1>
            </a>
            <a href="/retoVIernesEspecializadoSem3y4" className="btn diaSemana">
              <img src={corazon} className="cor" />
              <h1>Reto del Viernes</h1>
            </a>
            <a href="/retoSabadoEspecializadoSem3y4" className="btn diaSemana">
              <img src={medalla} className="cor" />
              <h1>Reto del Sábado</h1>
            </a>
            <a className="btn2 diaSemana btn3">
              <img src={luna} className="cor" />
              <h1>Domingo es libre</h1>
            </a>
          </div>
        </BlockUi>

        {/* <button onClick={this.logoutData}>Sign Out</button> */}
      </div>
    </div>
  );
};

export default Calendario;
