import React, { useState, useRef } from "react";
// import { Document, Page, pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Player from "react-player";
//importar css para estilar
import "../css/retos.css";

import corriendo from "../multimedia/Corriendo.png";
import pesa from "../multimedia/pesa.png";
import tenis from "../multimedia/Tenis.png";
import medalla from "../multimedia/Medalla.png";
import corazon from "../multimedia/Corazon.png";
import botella from "../multimedia/Botella.png";
import luna from "../multimedia/Luna.png";

import Policia from "./PoliciaPersonalizados";
import Aduana from "./ObtenerPersonalizados";

import { useParams } from "react-router-dom";

const RetoPersonalizado = () => {
  const dropdownRef = useRef(null);
  // const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);
  // const [isActive2, setIsActiv2] = useState(false);
  // const onClick2 = () => setIsActiv2(!isActive2);
  const [isActive3, setIsActive3] = useState(false);
  const onClick3 = () => setIsActive3(!isActive3);
  const [isActive4, setIsActive4] = useState(false);
  const onClick4 = () => setIsActive4(!isActive4);

  const [poli, setPoli] = useState(false);
  const [email, setEmail] = useState({});
  const [formData, setFormData] = useState({
    correo: "",
    nombre: "",
    fechaInicio: "",
    fechaFinal: "",
    poster: [],
    pdfEntrenamiento: [],
    pdfAlimentacion: [],

    descLunes: "",
    vidLunes: [],

    descMartes: "",
    vidMartes: [],

    descMiercoles: "",
    vidMiercoles: [],

    descJueves: "",
    vidJueves: [],

    descViernes: "",
    vidViernes: [],

    descSabado: "",
    vidSabado: [],
  });

  const { idUser } = useParams();

  const Revision = () => {
    if (poli == true) {
      return (
        <Aduana formData={formData} setFormData={setFormData} email={email} />
      );
    }
  };

  const MostrarReto = () => {
    if (formData.correo != "") {
      return (
        <div className="main">
          <h1 className="tituloReto">Hola! Bienvenido {formData.correo}</h1>

          <div className="calendarioRetos">
            <h1 className="semanaCalendario">{formData.nombre}</h1>

            <div className="semana">
              <div className="semret">
                <a className=" sem">
                  Fecha inicial: {formData.fechaInicio} --- Fecha final:{" "}
                  {formData.fechaFinal}
                </a>
              </div>
              <a
                href={"/retoLunesPersonalizado_" + idUser}
                className="btn diaSemana"
              >
                <img src={corriendo} className="cor" />
                <h1>Reto del Lunes</h1>
              </a>
              <a
                href={"/retoMartesPersonalizado_" + idUser}
                className="btn diaSemana"
              >
                <img src={pesa} className="cor" />
                <h1>Reto del Martes</h1>
              </a>
              <a
                href={"/retoMiercolesPersonalizado_" + idUser}
                className="btn diaSemana"
              >
                <img src={tenis} className="cor" />
                <h1>Reto del Miércoles</h1>
              </a>
              <a
                href={"/retoJuevesPersonalizado_" + idUser}
                className="btn diaSemana"
              >
                <img src={botella} className="cor" />
                <h1>Reto del Jueves</h1>
              </a>
              <a
                href={"/retoViernesPersonalizado_" + idUser}
                className="btn diaSemana"
              >
                <img src={corazon} className="cor" />
                <h1>Reto del Viernes</h1>
              </a>
              <a
                href={"/retoSabadoPersonalizado_" + idUser}
                className="btn diaSemana"
              >
                <img src={medalla} className="cor" />
                <h1>Reto del Sábado</h1>
              </a>
              <a className="btn2 diaSemana btn3">
                <img src={luna} className="cor" />
                <h1>Domingo es libre</h1>
              </a>
            </div>

            {/* <button onClick={this.logoutData}>Sign Out</button> */}
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <Policia
        poli={poli}
        setPoli={setPoli}
        email={email}
        setEmail={setEmail}
      />
      <div>{Revision()}</div>
      <div>{MostrarReto()}</div>
    </div>
  );
};

export default RetoPersonalizado;
