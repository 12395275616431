import React, { useState, useRef } from "react";
// import { Document, Page, pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Player from "react-player";
//importar css para estilar
import "../css/retos.css";
import imapdf from "../multimedia/PDF.png";
import calendario from "../multimedia/calendario.png";

import Policia from "./PoliciaPersonalizados";
import Aduana from "./ObtenerPersonalizados";

import { useParams } from "react-router-dom";

const RetoPersonalizado = () => {
  const dropdownRef = useRef(null);
  // const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);
  // const [isActive2, setIsActiv2] = useState(false);
  // const onClick2 = () => setIsActiv2(!isActive2);
  const [isActive3, setIsActive3] = useState(false);
  const onClick3 = () => setIsActive3(!isActive3);
  const [isActive4, setIsActive4] = useState(false);
  const onClick4 = () => setIsActive4(!isActive4);

  const [poli, setPoli] = useState(false);
  const [email, setEmail] = useState({});
  const [formData, setFormData] = useState({
    correo: "",
    nombre: "",
    fechaInicio: "",
    fechaFinal: "",
    poster: [],
    pdfEntrenamiento: [],
    pdfAlimentacion: [],

    descLunes: "",
    vidLunes: [],

    descMartes: "",
    vidMartes: [],

    descMiercoles: "",
    vidMiercoles: [],

    descJueves: "",
    vidJueves: [],

    descViernes: "",
    vidViernes: [],

    descSabado: "",
    vidSabado: [],
  });

  const { idUser } = useParams();

  const Revision = () => {
    if (poli == true) {
      return (
        <Aduana formData={formData} setFormData={setFormData} email={email} />
      );
    }
  };

  const MostrarReto = () => {
    if (formData.correo != "") {
      return (
        <section className="principal">
          <div>
            <h1 className="nombreCursoMovil">Lunes</h1>
            <section className="retomes">
              <div className="cursoCompu">
                <h1 className="nombreCursoCompu">
                  <img src={calendario} className="calendario" />
                  Lunes
                </h1>
                <h3 className="descCurso">
                  {formData.descLunes}
                  <br />
                  <br />
                  Fecha de inicio: {formData.fechaInicio}
                  <br />
                  Fecha final: {formData.fechaFinal}
                </h3>
              </div>
              <div className="videomes">
                <a href={"Reto_" + idUser}>
                  <img src={formData.poster} className="videoCurso" />
                </a>
              </div>
            </section>

            <section className="secposter">
              <div className="mainpos">
                <div className="Sidebar">
                  <img
                    src={
                      "https://tabsfitness.fra1.digitaloceanspaces.com/logo.png"
                    }
                    className="Logside"
                  />
                </div>
                <Player
                  url={formData.vidLunes}
                  className="imgCurso"
                  controls
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        onContextMenu: (e) => e.preventDefault(), //impide click derecho
                      },
                    },
                  }}
                />
                {/* <img src={poster} className="imgCurso" /> */}
                <div className="Sidebar">
                  <img
                    src={
                      "https://tabsfitness.fra1.digitaloceanspaces.com/logo.png"
                    }
                    className="Logside"
                  />
                </div>
              </div>
            </section>

            <div className="divDescargar">
              <button
                className="descargabutton"
                type="button"
                onClick={onClick}
              >
                {/* <a className="descargaPdfCurso" href={pdfEntrenamiento} download> */}
                <a className="descargaPdfCurso">PDF del entrenamiento</a>
                <img src={imapdf} className="pdfima" />
              </button>
              <Document
                ref={dropdownRef}
                file={formData.pdfEntrenamiento}
                className={`pdfs1 ${isActive ? "active" : "inactive"}`}
              >
                <Page pageNumber={1} />
                <Page pageNumber={4} />
              </Document>

              <button className="descargabutton" type="button">
                <a
                  className="descargaPdfCurso"
                  href={formData.pdfAlimentacion}
                  download
                >
                  Descargar PDF de la alimentación
                </a>
                <img src={imapdf} className="pdfima" />
              </button>
            </div>
          </div>
        </section>
      );
    }
  };

  return (
    <div>
      <Policia
        poli={poli}
        setPoli={setPoli}
        email={email}
        setEmail={setEmail}
      />
      <div>{Revision()}</div>
      <div>{MostrarReto()}</div>
    </div>
  );
};

export default RetoPersonalizado;
