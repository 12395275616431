import React, { useState } from "react";

import antesDes1 from "../multimedia/AntesyDespues/antesDespues1.png";
import antesDes2 from "../multimedia/AntesyDespues/antesDespues2.png";
import antesDes3 from "../multimedia/AntesyDespues/antesDespues3.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../css/antesyDespues.css";

import { Carousel } from "react-responsive-carousel";
import ObtenerAntesyDespues from "./ObtenerAntesyDespues";

const AntesyDespues = () => {
  const [data, setData] = useState({
    imagen1: "",
    imagen2: "",
    imagen3: "",
  });
  return (
    <div className="Antes-Despues">
      <ObtenerAntesyDespues setData={setData} />
      <Carousel>
        <div className="divAnDes">
          <div className="dobleAnDes">
            <h1 className="tituloAnDes">Antes</h1>
            <h1 className="tituloAnDes">Después</h1>
          </div>
          <div className="dobleAnDes">
            <h1 className="tituloAnDes">Antes</h1>
            <h1 className="tituloAnDes">Después</h1>
          </div>
          <img src={data.imagen1} className="imgAnDes" />
        </div>
        <div className="divAnDes">
          <div className="dobleAnDes">
            <h1 className="tituloAnDes">Antes</h1>
            <h1 className="tituloAnDes">Después</h1>
          </div>
          <div className="dobleAnDes">
            <h1 className="tituloAnDes">Antes</h1>
            <h1 className="tituloAnDes">Después</h1>
          </div>
          <img src={data.imagen2} className="imgAnDes" />
        </div>
        <div className="divAnDes">
          <div className="dobleAnDes">
            <h1 className="tituloAnDes">Antes</h1>
            <h1 className="tituloAnDes">Después</h1>
          </div>
          <div className="dobleAnDes">
            <h1 className="tituloAnDes">Antes</h1>
            <h1 className="tituloAnDes">Después</h1>
          </div>
          <img src={data.imagen3} className="imgAnDes" />
        </div>
      </Carousel>
    </div>
  );
};

export default AntesyDespues;
