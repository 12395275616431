import React, { Component } from "react";

import "../css/footer.css";

import insta from "../multimedia/insta.png";
import logo from "../multimedia/Logo.png";
import telefono from "../multimedia/telefono.png";
import mail from "../multimedia/mail.png";

const instaLink = () =>
  window.open("https://www.instagram.com/tabs.vazquez", "_blank");

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <h4 className="footerTexto">
          Soy Tabs y mi objetivo es ayudarte a transformar tu cuerpo a través de
          nuevos hábitos que cambiarán tu estilo de vida. Llevo muchos años
          haciendo ejercicio, alimentándome sanamente y ayudando a las personas
          a cumplir sus metas y estar más saludable. A través de mis programas
          podremos entrenar juntos hasta alcanzar nuevos objetivos a través de
          planes alimenticios adecuados a tu cuerpo.
        </h4>
        <div className="iconoFooter">
          <h4 className="nombreIcono">Whatsapp</h4>
          <h4 className="datoIcono">442 771 2756</h4>
          <img src={telefono} className="icono" />
        </div>
        <div className="iconoFooter">
          <h4 className="nombreIcono">Instagram</h4>
          <h4 className="datoIcono">@tabs.vazquez</h4>
          <a onClick={instaLink}>
            <img src={insta} className="iconoInsta" />
          </a>
        </div>
        {/* <h4 className="footerLinea"></h4>
        <div className="footerLista">
          <h4 className="footerRetos">RETO 1</h4>
          <h4 className="footerRetos">RETO 2</h4>
        </div>
        <div className="footerLista">
          <h4 className="footerRetos">CURSO 1</h4>
          <h4 className="footerRetos">CURSO 2</h4>
        </div>
        <div className="footerInstaDiv">
          <img src={insta} className="footerInsta" />
        </div>
        <img src={imgFooter1} className="footerImg" />
        <img src={logo} className="footerLogo" />
        <img src={imgFooter2} className="footerImg" /> */}
        {/* <div className="izquierdaF division">
          <span>ACERCA DE</span>
          <div className="redes">
            <img src={logo} alt="" />
            <img src={logo} alt="" />
            <img src={logo} alt="" />
          </div>
          <span className="copy">HELLO@HELLOCREATIVIDAD | AVISO LEGAL</span>
        </div>
        <div className="centroF division">
          <img src={logo} alt="" />
        </div>
        <div className="derechaF division">
          <div className="columnas">
            <p>Reto 1</p>
            <p>Reto 2</p>
            <p>Reto 3</p>
            <p>Reto 4</p>
          </div>
          <div className="columnas">
            <p>PRODUCTO</p>
            <p>PRODUCTO</p>
            <p>PRODUCTO</p>
            <p>PRODUCTO</p>
          </div>
        </div> */}
      </footer>
    );
  }
}

export default Footer;
