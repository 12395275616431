import React, { useState, useRef } from "react";
import { Redirect } from "react-router-dom";
// import { Document, Page, pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Player from "react-player";
//importar css para estilar
import "../css/retos.css";
import imapdf from "../multimedia/PDF.png";
import calendario from "../multimedia/calendario.png";

import Policia from "./policiaDeUsuarios";
import ObtenerDatosReto from "./ObtenerDatosReto";

const RetoLunes3 = () => {
  const dropdownRef = useRef(null);
  // const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);
  // const [isActive2, setIsActiv2] = useState(false);
  // const onClick2 = () => setIsActiv2(!isActive2);
  const [isActive3, setIsActive3] = useState(false);
  const onClick3 = () => setIsActive3(!isActive3);
  const [isActive4, setIsActive4] = useState(false);
  const onClick4 = () => setIsActive4(!isActive4);

  const [poli, setPoli] = useState(false);

  const [data, setData] = useState({
    reto: true,
    nombre: "",
    pdfEntrenamiento1y2: [],
    pdfEntrenamiento3y4: [],
    pdfMacrosMarcas: [],
    pdfAlimentacion: [],
    pdfFotos: [],
    poster: [],
    descLunes: "",
    vidLunes: [],
    descLunes3: "",
    vidLunes3: [],
    descMartes: "",
    vidMartes: [],
    descMartes3: "",
    vidMartes3: [],
    descMiercoles: "",
    vidMiercoles: [],
    descMiercoles3: "",
    vidMiercoles3: [],
    descJueves: "",
    vidJueves: [],
    descJueves3: "",
    vidJueves3: [],
    descViernes: "",
    vidViernes: [],
    descViernes3: "",
    vidViernes3: [],
    descSabado: "",
    vidSabado: [],
    descSabado3: "",
    vidSabado3: [],
  });

  const RevisarReto = () => {
    if (data.reto == true) {
      return null;
    } else {
      return <Redirect to={"/"} />;
    }
  };

  const Revision = () => {
    if (poli == true) {
      return (
        <section className="principal">
          <div>
            <h1 className="nombreCursoMovil">Lunes</h1>

            <section className="retomes">
              <div className="cursoCompu">
                <h1 className="nombreCursoCompu">
                  <img src={calendario} className="calendario" />
                  Lunes
                </h1>
                <h3 className="descCurso">{data.descLunes3}</h3>
              </div>
              <div className="videomes">
                <a href="/reto30Dias">
                  <img src={data.poster} className="videoCurso" />
                </a>
              </div>
            </section>

            <section className="secposter">
              <div className="mainpos">
                <div className="Sidebar">
                  <img
                    src={
                      "https://tabsfitness.fra1.digitaloceanspaces.com/logo.png"
                    }
                    className="Logside"
                  />
                </div>
                <Player
                  url={data.vidLunes3}
                  className="imgCurso"
                  controls
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        onContextMenu: (e) => e.preventDefault(), //impide click derecho
                      },
                    },
                  }}
                />
                {/* <img src={poster} className="imgCurso" /> */}
                <div className="Sidebar">
                  <img
                    src={
                      "https://tabsfitness.fra1.digitaloceanspaces.com/logo.png"
                    }
                    className="Logside"
                  />
                </div>
              </div>
            </section>

            <div className="divDescargar">
              <button
                className="descargabutton"
                type="button"
                onClick={onClick}
              >
                {/* <a className="descargaPdfCurso" href={pdfEntrenamiento} download> */}
                <a className="descargaPdfCurso">PDF del entrenamiento</a>
                <img src={imapdf} className="pdfima" />
              </button>
              <Document
                ref={dropdownRef}
                file={data.pdfEntrenamiento3y4}
                className={`pdfs1 ${isActive ? "active" : "inactive"}`}
              >
                <Page pageNumber={1} />
                <Page pageNumber={2} />
              </Document>

              <button
                className="descargabutton"
                type="button"
                onClick={onClick3}
              >
                <a className="descargaPdfCurso">PDF de macros y marcas</a>
                <img src={imapdf} className="pdfima" />
              </button>
              <Document
                ref={dropdownRef3}
                file={data.pdfMacrosMarcas}
                className={`pdfs3 ${isActive3 ? "active" : "inactive"}`}
              >
                <Page pageNumber={1} />
                <Page pageNumber={2} />
              </Document>

              <button
                className="descargabutton"
                type="button"
                onClick={onClick4}
              >
                <a className="descargaPdfCurso">Instrucciones de fotos</a>
                <img src={imapdf} className="pdfima" />
              </button>
              <Document
                ref={dropdownRef4}
                file={data.pdfFotos}
                className={`pdfs4 ${isActive4 ? "active" : "inactive"}`}
              >
                <Page pageNumber={1} />
                <Page pageNumber={2} />
                <Page pageNumber={3} />
              </Document>

              <button className="descargabutton" type="button">
                <a
                  className="descargaPdfCurso"
                  href={data.pdfAlimentacion}
                  download
                >
                  Descargar PDF de la alimentación
                </a>
                <img src={imapdf} className="pdfima" />
              </button>
            </div>
          </div>
        </section>
      );
    }
  };

  return (
    <div>
      <ObtenerDatosReto data={data} setData={setData} />
      {RevisarReto()}
      <Policia poli={poli} setPoli={setPoli} />
      <div>{Revision()}</div>
    </div>
  );
};

export default RetoLunes3;
