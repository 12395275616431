import React from "react";

class RetoNoviembre extends React.Component {
  render() {
    return (
      <div>
        <ul className="slideshow">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div style={{ textAlign: "center", position: "relative", zIndex: 1 }}>
          <h1 style={{ fontSize: 70 }}>Error 404</h1>
          <h2 style={{ fontSize: 50 }}>No se encontró la página</h2>
          <a href="/">
            <button
              style={{
                fontSize: 50,
                marginBottom: 60,
                backgroundColor: "#d76e41",
                cursor: "pointer",
                padding: 20,
              }}
            >
              Ir a la Homepage
            </button>
          </a>
        </div>
      </div>
    );
  }
}

export default RetoNoviembre;
