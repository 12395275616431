import React, { useState, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import { auth, db } from "../config/fbinit";
import { onAuthStateChanged } from "@firebase/auth";
import { collection, getDocs, query, where } from "@firebase/firestore";
import Swal from "sweetalert2";
//import PoliInmigrantes from "./policiaDeInmigrantes";

const RevisarReto = ({ setRetoMensual, setNombreReto, setReflexion }) => {
  useLayoutEffect(() => {
    const getLevel = async () => {
      try {
        const usersCollectionReference = collection(db, "RetosMensuales");
        //const q = query(usersCollectionReference, where("email", "==", email));
        const loggedUserData = await getDocs(usersCollectionReference);
        //console.log(loggedUserData.docs[0]);
        //   console.log(loggedUserData.docs[0].data().nombre);
        const nombre = loggedUserData.docs[0].data().nombre;
        if (nombre != "" && nombre != null) {
          setNombreReto(loggedUserData.docs[0].data().nombre);
          setReflexion(loggedUserData.docs[0].data().reflexion);
          setRetoMensual(true);
        } else {
          setRetoMensual(false);
        }
      } catch (e) {
        setRetoMensual(false);
        return null;
      }
    };
    getLevel();
  }, []);
  return null;
};

export default RevisarReto;
