import React, { useState, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import { auth, db } from "../config/fbinit";
import { onAuthStateChanged } from "@firebase/auth";
import { collection, getDocs, query, where } from "@firebase/firestore";
import Swal from "sweetalert2";
import PoliInmigrantes from "./policiaDeInmigrantes";

const Aduana = ({ data, setData }) => {
  useLayoutEffect(() => {
    const getLevel = async () => {
      try {
        const usersCollectionReference = collection(db, "AccesoPublico");
        const loggedUserData = await getDocs(usersCollectionReference);
        //   console.log("REFERENCIA", usersCollectionReference);
        const homepage = await loggedUserData.docs[0].data();

        setData({
          poster1: homepage.poster1,
          poster2: homepage.poster2,
          texto: homepage.textoVideo,
          video: homepage.videoHomePage,
          fecha: homepage.fecha,
        });
      } catch (e) {
        return null;
      }
    };
    getLevel();
  }, []);
  return null;
};

export default Aduana;
