import React from "react";
import "../css/acerca.css";
import ladrillos from "../multimedia/ladrillos.jpeg";
import insta from "../multimedia/instaColor.png";

const AcercaDe = () => {
  return (
    <div className="todo">
      <div className="nosotros">
        <h1 className="tituloDe">ACERCA DE TABS VAZQUEZ</h1>
      </div>
      <div className="acerca">
        <img className="ladrillos imgCompu" src={ladrillos} />
        <aside className="texto">
          <h2 className="textoDe">
            Soy Tabs y mi objetivo es ayudarte a transformar tu cuerpo a través
            de nuevos hábitos que cambiarán tu estilo de vida. Llevo muchos años
            haciendo ejercicio, alimentándome sanamente y ayudando a las
            personas a cumplir sus metas y estar más saludable. A través de mis
            programas podremos entrenar juntos hasta alcanzar nuevos objetivos a
            través de planes alimenticios adecuados a tu cuerpo.
          </h2>
          <h2 className="sigueme">
            Sígueme en mi Instagram donde podrás encontrar más tips y contenido
            que te puede ayudar a alcanzar estos nuevos objetivos.
          </h2>
          <div className="insta">
            <img className="instaim" src={insta} align="left" />
            <aside>
              <h2 className="tag">@tabs.Vázquez</h2>
            </aside>
          </div>
        </aside>
        <img className="ladrillos imgCel" src={ladrillos} />
      </div>
    </div>
  );
};

export default AcercaDe;
