import React, { useState } from "react";
import Player from "react-player";
import { browserName, CustomView, isMobileSafari } from "react-device-detect";
import ObtenerDatos from "./ObtenerPublicos";

//importar css para estilar
import "../css/homepage.css";

//importar los archivos multimedia a utilizar
import poster2 from "../multimedia/poster2.jpeg";
import poster1 from "../multimedia/poster1.png";
import imgVideo from "../multimedia/imgHpTemp.jpeg";

const Homepage = () => {
  const [data, setData] = useState({
    poster1: "",
    poster2: "",
    texto: "",
    fecha: "",
    video: "",
  });

  return (
    <div>
      <ObtenerDatos setData={setData} />
      <ul className="slideshow">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="principalHP">
        {/*primer parte del poster, mostrado solo en compu*/}
        <div className="posterDiv1">
          <img src={data.poster1} className="poster1" />
        </div>
        {/* texto que va encima del video */}
        <div className="textoVideo">
          <h1>{data.texto}</h1>
          <h2>{data.fecha}</h2>
        </div>
        {/*apartado del video */}

        <Player
          url={data.video}
          className="vidHp"
          controls
          playsinline={true}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
                onContextMenu: (e) => e.preventDefault(), //impide click derecho
                poster: imgVideo,
              },
            },
          }}
        />

        {/*segunda parte del poster*/}
        <div className="posterDiv">
          <img src={data.poster2} className="poster" />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
