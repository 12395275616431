import React from "react";
//import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Homepage from "./views/Homepage";
import Navbar from "./navbars/Navbar";
import Footer from "./footers/Footer";
// import Productos from "./views/Productos";
import AcercaDe from "./views/AcercaDe";
import Cursos from "./views/Reto30Dias";
import AntesyDespues from "./views/AntesyDespues";
import PagUsuarios from "./views/PagUsuarios";

import Error404 from "./views/404";

/*Reto mensual */
import Lunes from "./views/Lunes-1-2";
import Martes from "./views/Martes-1-2";
import Miercoles from "./views/Miercoles-1-2";
import Jueves from "./views/Jueves-1-2";
import Viernes from "./views/Viernes-1-2";
import Sabado from "./views/Sabado-1-2";
import Lunes3 from "./views/Lunes-3-4";
import Martes3 from "./views/Martes-3-4";
import Miercoles3 from "./views/Miercoles-3-4";
import Jueves3 from "./views/Jueves-3-4";
import Viernes3 from "./views/Viernes-3-4";
import Sabado3 from "./views/Sabado-3-4";

/*Reto especializado*/
import LunesEsp from "./views/LunesEsp-1-2";
import MartesEsp from "./views/MartesEsp-1-2";
import MiercolesEsp from "./views/MiercolesEsp-1-2";
import JuevesEsp from "./views/JuevesEsp-1-2";
import ViernesEsp from "./views/ViernesEsp-1-2";
import SabadoEsp from "./views/SabadoEsp-1-2";
import Lunes3Esp from "./views/LunesEsp-3-4";
import Martes3Esp from "./views/MartesEsp-3-4";
import Miercoles3Esp from "./views/MiercolesEsp-3-4";
import Jueves3Esp from "./views/JuevesEsp-3-4";
import Viernes3Esp from "./views/ViernesEsp-3-4";
import Sabado3Esp from "./views/SabadoEsp-3-4";

/*Reto personalizado*/
import LunesPerso from "./views/LunesPerso";
import MartesPerso from "./views/MartesPerso";
import MiercolesPerso from "./views/MiercolesPerso";
import JuevesPerso from "./views/JuevesPerso";
import ViernesPerso from "./views/ViernesPerso";
import SabadoPerso from "./views/SabadoPerso";

import RetoPersonalizado from "./views/RetoPersonalizado";
import RetoEspecializado from "./views/RetoEspecializado";

import SignUp from "./views/signUp";
import Bridge from "./views/userLoginBridge";
import Contraseña from "./views/resetContra";

const App = () => {
  return (
    <div>
      <header>
        <Navbar />
      </header>

      <Router>
        <Switch>
          {/* Switch Solo carga 1 direccion */}
          <Route path="/AcercaDe">
            <AcercaDe />
          </Route>
          <Route path="/AntesyDespues">
            <AntesyDespues />
          </Route>

          <Route path="/LogIn">
            <SignUp />
          </Route>
          <Route path="/recuperarContraseña">
            <Contraseña />
          </Route>

          {/*RETO MENSUAL 30 DIAS*/}
          {/* Semana 1 y 2 */}
          <Route path="/retoLunesSem1y2">
            <Lunes />
          </Route>
          <Route path="/retoMartesSem1y2">
            <Martes />
          </Route>
          <Route path="/retoMiercolesSem1y2">
            <Miercoles />
          </Route>
          <Route path="/retoJuevesSem1y2">
            <Jueves />
          </Route>
          <Route path="/retoViernesSem1y2">
            <Viernes />
          </Route>
          <Route path="/retoSabadoSem1y2">
            <Sabado />
          </Route>
          {/* Semana 3 y 4 */}
          <Route path="/retoLunesSem3y4">
            <Lunes3 />
          </Route>
          <Route path="/retoMartesSem3y4">
            <Martes3 />
          </Route>
          <Route path="/retoMiercolesSem3y4">
            <Miercoles3 />
          </Route>
          <Route path="/retoJuevesSem3y4">
            <Jueves3 />
          </Route>
          <Route path="/retoViernesSem3y4">
            <Viernes3 />
          </Route>
          <Route path="/retoSabadoSem3y4">
            <Sabado3 />
          </Route>

          {/*RETO ESPECIALIZADO*/}
          {/* Semana 1 y 2 */}
          <Route path="/retoLunesEspecializadoSem1y2">
            <LunesEsp />
          </Route>
          <Route path="/retoMartesEspecializadoSem1y2">
            <MartesEsp />
          </Route>
          <Route path="/retoMiercolesEspecializadoSem1y2">
            <MiercolesEsp />
          </Route>
          <Route path="/retoJuevesEspecializadoSem1y2">
            <JuevesEsp />
          </Route>
          <Route path="/retoViernesEspecializadoSem1y2">
            <ViernesEsp />
          </Route>
          <Route path="/retoSabadoEspecializadoSem1y2">
            <SabadoEsp />
          </Route>
          {/* Semana 3 y 4 */}
          <Route path="/retoLunesEspecializadoSem3y4">
            <Lunes3Esp />
          </Route>
          <Route path="/retoMartesEspecializadoSem3y4">
            <Martes3Esp />
          </Route>
          <Route path="/retoMiercolesEspecializadoSem3y4">
            <Miercoles3Esp />
          </Route>
          <Route path="/retoJuevesEspecializadoSem3y4">
            <Jueves3Esp />
          </Route>
          <Route path="/retoViernesEspecializadoSem3y4">
            <Viernes3Esp />
          </Route>
          <Route path="/retoSabadoEspecializadoSem3y4">
            <Sabado3Esp />
          </Route>

          {/*RETO PERSONALIZDO*/}
          <Route path="/retoLunesPersonalizado_:idUser">
            <LunesPerso />
          </Route>
          <Route path="/retoMartesPersonalizado_:idUser">
            <MartesPerso />
          </Route>
          <Route path="/retoMiercolesPersonalizado_:idUser">
            <MiercolesPerso />
          </Route>
          <Route path="/retoJuevesPersonalizado_:idUser">
            <JuevesPerso />
          </Route>
          <Route path="/retoViernesPersonalizado_:idUser">
            <ViernesPerso />
          </Route>
          <Route path="/retoSabadoPersonalizado_:idUser">
            <SabadoPerso />
          </Route>

          <Route path="/Cuenta">
            <PagUsuarios />
          </Route>

          <Route path="/Reto_:idUser">
            <RetoPersonalizado />
          </Route>

          <Route path="/reto30Dias">
            <Cursos />
          </Route>

          <Route path="/retoEspecializado">
            <RetoEspecializado />
          </Route>

          <Route path="/signUp">
            <Bridge />
          </Route>

          <Route path="/:a">
            <Error404 />
          </Route>

          <Route path="/">
            <Homepage />
          </Route>
        </Switch>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
