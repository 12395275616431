import React, { useRef, useState, useEffect } from "react";

import "../css/navbar.css";

import logo from "../multimedia/Logo.png";
import menu from "../multimedia/menu.png";
import x from "../multimedia/x.png";
import instaColor from "../multimedia/instaColor.png";
import login from "../multimedia/Login.png";
import pesita from "../multimedia/Pesita.png";
import cronos from "../multimedia/Cronos.png";
import antesDes from "../multimedia/RelojAntesDes.png";

import { auth, db } from "../config/fbinit";
import { onAuthStateChanged, signOut } from "@firebase/auth";

import RevisionReto from "./RevisarReto";
import RevisionRetoEsp from "./RevisarRetoEsp";

const Navbar = () => {
  //esto esta para abrir un menu al presionar el boton de la izquierda de la navbar
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);

  const [retoMensual, setRetoMensual] = useState(false);
  const [nombreReto, setNombreReto] = useState(null);
  const [reflexion, setReflexion] = useState(null);

  const [retoEsp, setRetoEsp] = useState(false);
  const [nombreRetoEsp, setNombreRetoEsp] = useState(null);
  const [reflexionRetoEsp, setReflexionRetoEsp] = useState(null);

  const [user, setUser] = useState({});

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  const logoutData = async (data) => {
    await signOut(auth);
  };

  const RevisarReto = () => {
    if (retoMensual == true) {
      return (
        <ul className="navLista puesto1">
          <a href="/reto30Dias">
            <span>{nombreReto}</span>
          </a>
          <img src={pesita} className="pesita"></img>
          <span className="glyphicon glyphicon-search"></span>
        </ul>
      );
    }
  };
  const RevisarRetoEsp = () => {
    if (retoEsp == true) {
      return (
        <ul className="navLista puesto1">
          <a href="/retoEspecializado">
            <span>{nombreRetoEsp}</span>
          </a>
          <img src={pesita} className="pesita"></img>
          <span className="glyphicon glyphicon-search"></span>
        </ul>
      );
    }
  };

  //hace que el boton de instagram te redirija a su pagina
  const insta = () =>
    window.open("https://www.instagram.com/tabs.vazquez", "_blank");

  return (
    <div>
      <div className="nav">
        {/*apartado del boton del menu extra*/}
        <div className="navMenu">
          <img onClick={onClick} src={menu} className="navMenuImg" />
        </div>
        <div className="navDLogo">
          <a href="/">
            <img
              src={"https://tabsfitness.fra1.digitaloceanspaces.com/logo.png"}
              className="navLogo"
            />
          </a>
        </div>
        <div className="navDerecha">
          <a onClick={insta}>
            <img src={instaColor} className="navInstaColor" />
          </a>
        </div>
      </div>

      {/*Apartado que contiene el menu extra */}
      <ul
        ref={dropdownRef}
        className={`navDropdown ${isActive ? "active" : "inactive"}`}
      >
        <img onClick={onClick} src={x} className="navMenuImgLista" />
        <ul className="navLista puesto1">
          {user == null ? (
            <a href="/signUp">
              <span>Log In</span>
            </a>
          ) : (
            <a onClick={logoutData} href="/signUp">
              <span>Sign Out</span>
            </a>
          )}
          <img src={login} className="login"></img>
        </ul>
        <RevisionReto
          setRetoMensual={setRetoMensual}
          setNombreReto={setNombreReto}
          setReflexion={setReflexion}
        />
        {RevisarReto()}
        <RevisionRetoEsp
          setRetoEsp={setRetoEsp}
          setNombreRetoEsp={setNombreRetoEsp}
          setReflexionEsp={setReflexionRetoEsp}
        />
        {RevisarRetoEsp()}
        <ul className="navLista puesto1">
          <a href="/AntesyDespues">
            <span>Antes y Después</span>
          </a>
          <img src={antesDes} className="cronos"></img>
        </ul>
        <ul className="navLista puesto1">
          <a href="/AcercaDe">
            <span>Acerca de mí</span>
          </a>
          <img src={cronos} className="cronos"></img>
        </ul>
        <ul className="navLista puesto1">
          {user != null ? (
            <a href="/Cuenta">
              <span>Mi cuenta</span>
            </a>
          ) : (
            <span />
          )}
        </ul>
      </ul>
    </div>
  );
};

export default Navbar;
