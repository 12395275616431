import React, { useState, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import { auth, db } from "../config/fbinit";
import { onAuthStateChanged } from "@firebase/auth";
import { collection, getDocs, query, where } from "@firebase/firestore";
import Swal from "sweetalert2";
import PoliInmigrantes from "./policiaDeInmigrantes";

const Aduana = ({ data, setData }) => {
  const [user, setUser] = useState({});
  const [level, setLevel] = useState(0);
  const [amigo, setAmigito] = useState(0);

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  useLayoutEffect(() => {
    const getLevel = async () => {
      try {
        const usersCollectionReference = collection(db, "RetosEspecializados");
        const loggedUserData = await getDocs(usersCollectionReference);
        console.log(loggedUserData.docs[0]);
        //   console.log("REFERENCIA", usersCollectionReference);
        const reto = loggedUserData.docs[0].data();
        if (reto.nombre != null && reto.nombe != "") {
          setData({
            reto: true,
            nombre: reto.nombre,
            pdfEntrenamiento1y2: reto.pdfEntrenamiento1y2,
            pdfEntrenamiento3y4: reto.pdfEntrenamiento3y4,
            pdfMacrosMarcas: reto.pdfMacrosMarcas,
            pdfAlimentacion: reto.pdfAlimentacion,
            pdfFotos: reto.pdfFotos,
            poster: reto.poster,
            descLunes: reto.descLunes,
            vidLunes: reto.vidLunes,
            descLunes3: reto.descLunes3,
            vidLunes3: reto.vidLunes3,
            descMartes: reto.descMartes,
            vidMartes: reto.vidMartes,
            descMartes3: reto.descMartes3,
            vidMartes3: reto.vidMartes3,
            descMiercoles: reto.descMiercoles,
            vidMiercoles: reto.vidMiercoles,
            descMiercoles3: reto.descMiercoles3,
            vidMiercoles3: reto.vidMiercoles3,
            descJueves: reto.descJueves,
            vidJueves: reto.vidJueves,
            descJueves3: reto.descJueves3,
            vidJueves3: reto.vidJueves3,
            descViernes: reto.descViernes,
            vidViernes: reto.vidViernes,
            descViernes3: reto.descViernes3,
            vidViernes3: reto.vidViernes3,
            descSabado: reto.descSabado,
            vidSabado: reto.vidSabado,
            descSabado3: reto.descSabado3,
            vidSabado3: reto.vidSabado3,
          });
        } else {
          setData({
            reto: false,
          });
        }
      } catch (e) {
        setData({
          reto: false,
        });
        return null;
      }
    };
    getLevel();
  }, [user, level]);
  return null;
};

export default Aduana;
